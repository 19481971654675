"use strict";

import Plyr from 'plyr';
import styles from 'plyr/dist/plyr.css';

const plyr = {
    instances: [],
    elements: {
        instance: '.js-plyr'
    },
    init() {
        const self = this,
            elements = document.querySelectorAll(self.elements.instance);

        if (elements.length) {
            elements.forEach(function (el) {
                self.instances[el.id] = new Plyr(el);
            });
        }
    },
    reset() {
        const self = this,
            elements = document.querySelectorAll(self.elements.instance);

        if (elements.length) {
            elements.forEach(function (el) {
                if (self.instances[el.id]) self.instances[el.id].destroy();

                self.instances[el.id] = new Plyr(el);
            });
        }
    }
};

export default plyr